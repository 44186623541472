import React from "react";
import { PageLayout } from "./components/PageLayout";
import { useMsalAuthentication } from "@azure/msal-react";
import { IcdHeader } from "./components/IcdHeader";
import { SiteMessage} from "./components/SiteMessage";

function App() {
  const {login, result, error} = useMsalAuthentication("redirect");
  
  return (
    <div className="App">
      <sdds-theme name="scania"></sdds-theme>
      <SiteMessage>You are in {process.env.REACT_APP_STAGE_FULL} environment</SiteMessage>
      <IcdHeader></IcdHeader>
      <PageLayout>
          <p>This will be the ICDICS 2.0 Playground</p>
          <icdics-list></icdics-list>
      </PageLayout>
    </div>
  );
}

export default App;