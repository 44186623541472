import React from "react";

export const IcdHeader = (props) => {
  return (
    <>
      <header>
        <nav class="sdds-nav">
          <div class="sdds-nav__left">
            <div class="sdds-nav__app-name">ICD/ICS 2.0</div>
          </div>
          <div class="sdds-nav__right">
            <a class="sdds-nav__item sdds-nav__app-logo" href="#"></a>
          </div>
        </nav>
      </header>

      {props.children}
    </>
  );
};
