let redirecturi = (process.env.NODE_ENV !== 'production' ? "http://localhost:3000" : `https://playground.${process.env.REACT_APP_STAGE}.icdics.${process.env.REACT_APP_SCANIA_STAGE}.aws.scania.com/`)
const authorityUri = "https://login.microsoftonline.com/";

export const msalConfig = {
    auth: {
      clientId: `${process.env.REACT_APP_MSAL_CLIENTID}`,
      authority: authorityUri + `${process.env.REACT_APP_MSAL_TENANT}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: redirecturi,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  };