import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";

export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <div class="sdds-banner">

                <div class="sdds-banner-body">
                    <h6 class="sdds-banner-header">{ isAuthenticated ? <span>Signed In</span> : <span>Not signed In</span> }</h6>
                </div>

                <div class="sdds-banner-close"></div>
            </div>
            
            {props.children}
        </>
    );
};