import React from "react";

export const SiteMessage = (props) => {
  return (
    <>
      <div class="sdds-banner">
        <div class="sdds-banner-body">
          <h6 class="sdds-banner-header">{props.children}</h6>
        </div>

        <div class="sdds-banner-close"></div>
      </div>
    </>
  );
};
